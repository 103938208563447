import { connectStart, connectDelete } from "../store/query";

export default {
    props: {
        userKey: String,
        connectId: String,
    },
    data () {
        return {
            status: {},
        }
    },
    computed: {
        isNew () {
            return this.userKey === 'new'
        }
    },
    mounted () {
        if (this.isNew) return
        let key = this.connectId || ''
        key = key + (this.userKey || '')
        this.status = this.vuex_connects[key]
    },
    methods: {
        mixStartConnect (d) {
            let uk = this.isNew && Date.now().toString() || this.userKey
            return connectStart(this.connectId, uk, d)
        },
        mixDeleteConnect (d) {
            return new Promise((resovle, reject)=>{
                this.$fs.modal({
                    title: '确认解除连接',
                    text: '将取消授权并删除此相关连接的用户信息。',
                    confirm: ()=>{
                        connectDelete(this.connectId, this.userKey, d).then(res=>{
                            return resovle(res)
                        }).catch(err=>{
                            return reject(err)
                        })
                    },
                    cancel: ()=>{
                        return reject()
                    }
                })
            })
        }
    }
}