<template>
    <div class="mt-3">
        <v-text-field
            label="AccessKey"
            outlined
            dense
            v-model="accessData.accessKey"
            autocomplete="off"
        />
        <v-text-field
            label="SecretKey"
            outlined
            dense
            v-model="accessData.secretKey"
            autocomplete="off"
        />
        <v-autocomplete
            v-model="accessData.regionUrl"
            label="默认储存的区域"
            outlined
            dense
            :items="regions"
            item-text="name"
            item-value="url"
            autocomplete="off"
        />
        <v-text-field
            label="默认储存的空间名称（Bucket）"
            v-model="accessData.bucketName"
            outlined
            dense
            autocomplete="off"
        />
        <v-text-field
            label="七牛子域名或已绑定的自定义域名"
            v-model="accessData.domain"
            autocomplete="off"
            outlined
            dense
        />
        <v-btn @click="doSave" class="primary">保存</v-btn>
    </div>
</template>

<script>
import MixinConnectBase from "@/mixins/connectBase";

export default {
    mixins: [MixinConnectBase],
    data () {
        return {
            accessData: {
                accessKey: '',
                secretKey: '',
                regionUrl: '',
                bucketName: '',
                domain: '',
            },
            regions: [{
                name: '华东',
                key: '',
                url: 'https://upload.qiniup.com'
            }, {
                name: '华北',
                key: '-z1',
                url: 'https://upload-z1.qiniup.com'
            }, {
                name: '华南',
                key: '-z2',
                url: 'https://upload-z2.qiniup.com'
            }, {
                name: '北美',
                key: '-na0',
                url: 'https://upload-na0.qiniup.com'
            }, {
                name: '东南亚',
                key: '-as0',
                url: 'https://upload-as0.qiniup.com'
            }, {
                name: '华东-浙江2',
                key: '-cn-east-2',
                url: 'https://upload-cn-east-2.qiniup.com'
            }]
        }
    },
    watch: {
        status (v) {
            let ad = v && (v !== -1) && v.access_data || {}
            this.accessData = ad
        }
    },
    methods: {
        doSave () {
            this.mixStartConnect(this.accessData).then(res=>{
                this.$fs.toast('保存成功')
                this.$router.go(-1)
            })
        },
    }
}
</script>